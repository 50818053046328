<template>
  <div class="Ldx">
    <div v-if="!isApp" class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="" />
    </div>
    <img src="/subject/Ldx/wap/banner.jpg" class="banner" alt="" />
    <div class="part1">
      <img src="/subject/Ldx/wap/tit1.png" class="title" alt="" />
      <div class="threeBox">
        <img src="/subject/Ldx/wap/picThree.png" class="picThree" alt="" />
      </div>
      <p class="p1">
        好友每交易1手,您可获得<b>5美元</b>的现金奖励<br />
        奖励可提现；邀请越多，赢得越多<br />
        最高可达<b>6688美元</b>现金奖励
      </p>
      <a href="" @click="openUser" class="btnReal"></a>
      <p class="p2">保存邀请海报，分享好友齐赚钱</p>
    </div>

    <div class="part2">
      <img src="/subject/Ldx/wap/tit2.png" class="title" alt="" />
      <span
        class="rules"
        @click="
          () => {
            activityDetailsDialog = true;
          }
        "
        >活動詳情 ></span
      >
      <img src="/subject/Ldx/wap/big.png" class="bigsec" />
      <p>
        <!-- 选对方法，智慧理财<br /> -->
        好友交易您收益，马上行动起来
      </p>
      <div class="btnBox">
        <a href="" class="live800" @click="openKefu"></a>
        <a href="" @click="openUser" class="btnReal"></a>
      </div>
    </div>

    <div class="part3">
      <img src="/subject/Ldx/wap/tit3.png" class="title" alt="" />
      <img src="/subject/Ldx/wap/eggs.png" class="eggs" alt="" />
      <img src="/subject/Ldx/wap/words.png" class="words" alt="" />
      <a href="" class="btnInvite"></a>
      <p>邀请1位好友，得最高<b>$6688，邀请人数无上限</b></p>
    </div>

    <div class="part4">
      <img src="/subject/Ldx/wap/tit4.png" class="title" alt="" />
      <img src="/subject/Ldx/wap/phone.png" class="phone" alt="" />
      <img src="/subject/Ldx/wap/jiantou.png" class="jiantou" alt="" />
      <div class="four-box">
        <img src="/subject/Ldx/wap/pic1.png" alt="" />
        <img src="/subject/Ldx/wap/pic2.png" alt="" />
        <img src="/subject/Ldx/wap/pic3.png" alt="" />
        <img src="/subject/Ldx/wap/pic4.png" alt="" />
      </div>
      <!-- <div class="apps">
        <p>
          <span>
            <img src="/subject/Ldx/iosCode.png" alt="" />
          </span>
          <span>ios下载</span>
        </p>
        <p>
          <span>
            <img src="/subject/Ldx/androidcode.png" alt="" />
          </span>
          <span>Android下载</span>
        </p>
      </div> -->
      <!-- <p class="lastP">
        红狮启富App<br />
        您的智富伙伴<br />
        更方便快捷的交易，更及时的新闻资讯和优惠信息
      </p> -->
    </div>
    <contactusVue v-if="!isApp"></contactusVue>
    <div v-if="activityDetailsDialog" class="activityDetailsDialog">
      <img
        @click="
          () => {
            activityDetailsDialog = false;
          }
        "
        src="/subject/worldCup/closer2.png"
        alt=""
      />
      <div>
        <h3>· 活动时间：</h3>
        <p>长期有效；</p>
        <h3>1. 参与条件：</h3>
        <p>
          推荐人与被推荐人都为本平台激活用户<br />推荐人完成推荐（与被推荐人绑定邀请码或邀请关系）
        </p>
        <h3>2. 活动奖励:</h3>
        <p>
          (1) 推荐人<br />
          a.被推荐人自完成开户起90天内，被推荐人每交易1手，推荐人即可获得5美元/手的现金奖励（不足1手，按比例发放），最多可获得6688美元的现金奖励；<br />
          b.推荐人所获得的现金奖励按自然月领取，推荐人仅需保持账户活跃状态，即账户每个自然月都有真实交易记录（开仓、平仓或持仓都可以，无手数要求）即可持续参与活动，否则，则视为自动退出该活动。<br />
          c.推荐人可推荐多人参与，被推荐人只能有一个对应的推荐人。若推荐人成功推荐多位被推荐人，那么5美元/手现金奖励可累计领取。<br />
        </p>
        <p>
          3、推荐方式： ①
          推荐人可复制分享链接或保存邀请海报，通过微信、QQ、邮箱等方式发送给好友，好友在专属推荐链接内开立真实账户即可完成推荐（如果遇到开启异常，可以复制链接到浏览器打开）
        </p>
        <p>
          ②
          推荐人将邀请码发送给好友，好友开立真实账户后，在用户中心内绑定邀请码后即可完成推荐。
        </p>
        <p>4、活动奖励：</p>
        <p>
          ① 现金奖励将以美元现金的形式发放至客户的用户中心；<br />
          ②
          现金奖励提取后发放至客户MT4真实交易账户中，该笔资金可提现亦可继续用于交易；<br />
          ③ 现金奖励每月1-7日进行审核，审核完成之后，符合活动条件方可发放
        </p>
        <h3>· 注意事项</h3>
        <p>
          1. 参加原邀请好友活动的客户按照原活动规则执行，如有疑问请咨询客服；<br />
          2.
          同一真实交易账户不可被多次邀请；每位被推荐人仅能为推荐人带来从注册开户开始，有效期3个月（90天）的现金奖励；<br />
          3.
          交易手数的计算：本活动只针对伦敦金伦敦银交易的手数计算，开仓一手再平仓一手，如此来回为一手；<br />
          4. 我平台保留随时修订、暂停、终止本活动及相关规则条款之权利及解释权。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ContactusVue from "../../Contactus.vue";
export default {
  name: "LdxWap",
  components: {
    ContactusVue,
  },
  data() {
    return {
      hoverControl: "",
      activityDetailsDialog: false,
      navBarFixed: false,
      scroll: "",
      isApp: false,
    };
  },
  methods: {
    openKefu() {
      window.open(
        this.$parent.live800UrlUc
      );
    },
    openUser() {
      window.open("https://www.rlcproltd.net/uc/signUp/real");
    },
    // 返回上级页面
    goback() {
      window.location.href = sessionStorage.getItem("craig");
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if (this.scroll > 90) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
  },
  created() {
    this.$emit("controlShow", true);
    // 判断是从A前还是A后跳转过来
    if (!sessionStorage.getItem("craig")) {
      if (!this.$route.query.craig) {
        sessionStorage.setItem("craig", window.location.origin);
      } else {
        sessionStorage.setItem("craig", this.$route.query.craig);
      }
    }
    if (document.body.clientWidth > 500) {
      this.$router.push({
        name: "LdxPC",
      });
    }
    if(this.$route.query.isApp){
      this.isApp = true;
    }
  },
  mounted() {
    document.title = "顺顺发发，邀友共享"
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.tabbar {
  background-color: #ffffff;
  padding: 10px 30px;
  img {
    width: 130px;
  }
}
.navBarFixed {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
}
.Ldx {
  width: 100%;
  .banner {
    width: 100%;
    height: 677px;
    display: block;
  }
  .title {
    width: 100%;
    height: 45px;
    display: block;
  }
  .part1 {
    background: #398572;
    padding-top: 20px;
    padding-bottom: 20px;
    .title {
      display: block;
      width: 354px;
      height: 57px;
      margin: 0 auto;
    }
    .threeBox {
      width: 100%;
      overflow: scroll;
      margin-top: 30px;
      padding-left: 82px;
      box-sizing: border-box;
      img {
        display: block;
        height: 224px;
      }
    }
    .p1 {
      color: #fde0c0;
      text-align: center;
      font-size: 16px;
      margin: 30px 0 10px 0;
      line-height: 30px;
      b {
        color: #6df7ff;
        font-weight: normal;
      }
    }
    .btnReal {
      display: block;
      margin: 0 auto;
      background-image: url("/subject/Ldx/wap/btn1.png");
      background-position-x: center;
      background-position-y: top;
      background-repeat: no-repeat;
      background-size: cover;
      width: 235px;
      height: 75px;
    }
    .p2 {
      color: #fde0c1;
      font-size: 14px;
      text-align: center;
    }
  }
  .part2 {
    background: #eef3e2;
    padding-bottom: 30px;
    padding-top: 30px;
    overflow: hidden;
    .title {
      display: block;
      width: 354px;
      height: 57px;
      margin: 0 auto;
    }
    .rules {
      display: block;
      cursor: pointer;
      color: #286d5c;
      text-decoration: underline;
      font-size: 16px;
      width: 85px;
      padding: 6px;
      margin: 15px auto;
    }
    .bigsec {
      display: block;
      width: 100%;
    }
    p {
      color: #2a6a50;
      font-size: 20px;
      text-align: center;
      line-height: 30px;
      margin-top: 30px;
    }
    .btnBox {
      text-align: center;
      margin-top: 10px;
      .live800 {
        width: 235px;
        height: 75px;
        margin: 0 auto;
        display: block;
        background-image: url("/subject/Ldx/wap/live800.png");
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .btnReal {
        display: block;
        margin: 0 auto;
        width: 235px;
        height: 75px;
        background-image: url("/subject/Ldx/wap/btn-r.png");
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  .part3 {
    background: #348172;
    padding-top: 20px;
    padding-bottom: 20px;
    .title {
      display: block;
      width: 354px;
      height: 57px;
      margin: 0 auto;
    }
    .eggs {
      display: block;
      width: 333px;
      height: 215px;
      margin: 0 auto;
    }
    .words {
      display: block;
      width: 221px;
      height: 88px;
      margin: 0 auto;
    }
    .btnInvite {
      display: block;
      margin: 0 auto;
      background-image: url("/subject/Ldx/wap/btn-invite.png");
      background-position-x: center;
      background-position-y: top;
      background-repeat: no-repeat;
      background-size: cover;
      width: 235px;
      height: 75px;
    }
    p {
      color: #fde0c1;
      text-align: center;
      b {
        color: #ffb847;
      }
    }
  }
  .part4 {
    background: #eef3e2;
    padding-bottom: 20px;
    padding-top: 20px;
    .title {
      display: block;
      width: 357px;
      height: 57px;
      margin: 0 auto;
    }
    .phone {
      display: block;
      margin: 0 auto;
      margin-top: 30px;
      margin-bottom: 30px;
      width: 210px;
      height: 276px;
    }
    .jiantou {
      display: block;
      width: 23px;
      height: 41px;
      margin: 0 auto;
    }
    .four-box {
      text-align: center;
      img {
        display: inline-block;
        width: 140px;
        height: 167.5px;
      }
      img:nth-child(1) {
        margin-top: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
      }
      img:nth-child(2) {
        margin-bottom: 20px;
      }
      img:nth-child(3) {
        margin-right: 20px;
      }
    }
    .apps {
      margin: 0 auto;
      text-align: center;
      display: flex;
      margin-top: 30px;
      margin-bottom: 30px;
      p {
        flex: 1;
        img {
          display: block;
          width: 100px;
          height: 100px;
          margin: 0 auto;
        }
        span {
          text-align: center;
        }
      }
    }
    .lastP {
      color: #286d5c;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
    }
  }
  .activityDetailsDialog {
    width: 80%;
    height: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 8px 15px;
    overflow-y: scroll;
    img {
      float: right;
      width: 20px;
    }
    h3 {
      font-size: 15px;
      color: #f04f54;
      margin: 8px 0;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      span {
        font-weight: 600;
      }
    }
  }
}
</style>